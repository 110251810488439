// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 6px;
  height: 650px;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.grid-box {
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  padding: 4px 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 4px;
}

.grid-box.expanded {
  grid-column: 1/-1;
  grid-row: 1/-1;
}

.grid-container.grid-collapsed .grid-box:not(.expanded) {
  display: none;
}

.grid-box, .grid-container {
  transition: all 0.3s ease-in-out;
}`, "",{"version":3,"sources":["webpack://./src/Main/GraphDashboard/Graph.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,2BAAA;EACA,QAAA;EACA,aAAA;EACA,WAAA;EACA,gCAAA;AACJ;;AAEE;EACE,aAAA;EACA,sBAAA;EACA,gCAAA;EACA,gBAAA;EACA,2CAAA;EACA,kBAAA;AACJ;;AAEE;EACE,iBAAA;EACA,cAAA;AACJ;;AAEE;EACE,aAAA;AACJ;;AAEE;EACE,gCAAA;AACJ","sourcesContent":[".grid-container {\n    display: grid;\n    grid-template-columns: 1fr 1fr; \n    grid-template-rows: 1fr 1fr;    \n    gap: 6px;                      \n    height: 650px;                  \n    width: 100%;                    \n    transition: all 0.3s ease-in-out;\n  }\n  \n  .grid-box {\n    display: flex;\n    flex-direction: column;\n    transition: all 0.3s ease-in-out;\n    padding: 4px 8px;\n    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;\n    border-radius: 4px;\n  }\n  \n  .grid-box.expanded {\n    grid-column: 1 / -1; \n    grid-row: 1 / -1;   \n  }\n  \n  .grid-container.grid-collapsed .grid-box:not(.expanded) {\n    display: none;  \n  }\n  \n  .grid-box, .grid-container {\n    transition: all 0.3s ease-in-out;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
