// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-container {
  background-color: F3F3E0;
  border-radius: 5px;
  padding-bottom: 4px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.product-container-header {
  padding: 6px 8px;
  border-radius: 5px;
  background: #f3f1f1;
}

.product-container-description {
  padding: 6px 8px;
}

.product-header {
  position: sticky;
  top: 0px;
  padding: 8px 6px;
  z-index: 10;
  background-color: #f3f1f1;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}`, "",{"version":3,"sources":["webpack://./src/Main/Setting/Products/Product.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,kBAAA;EACA,mBAAA;EACA,sFAAA;AACF;;AAGA;EACE,gBAAA;EACA,kBAAA;EACA,mBAAA;AAAF;;AAGA;EACI,gBAAA;AAAJ;;AAGA;EACE,gBAAA;EACA,QAAA;EACA,gBAAA;EACA,WAAA;EACA,yBAAA;EACA,kBAAA;EACA,sFAAA;AAAF","sourcesContent":[".product-container {\n  background-color: F3F3E0;\n  border-radius: 5px;\n  padding-bottom: 4px;\n  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,\n    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;\n}\n\n.product-container-header {\n  padding: 6px 8px;\n  border-radius: 5px;\n  background: #f3f1f1;\n}\n\n.product-container-description{\n    padding: 6px 8px;\n}\n\n.product-header{\n  position: sticky;\n  top: 0px;\n  padding: 8px 6px;\n  z-index: 10;\n  background-color: #f3f1f1;\n  border-radius: 5px;\n  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,\n    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
