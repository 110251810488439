// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-table-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.lead-tag {
  display: inline-block;
  max-width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.table-head-heading {
  font-size: 14px;
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/Main/Accounts/Accounts.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,WAAA;AACJ;;AAEA;EACI,qBAAA;EACA,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,sBAAA;AACJ;;AAEE;EACE,eAAA;EACA,gBAAA;AACJ","sourcesContent":[".account-table-header{\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    width: 100%;\n}\n\n.lead-tag {\n    display: inline-block;\n    max-width: 80px;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    vertical-align: middle;\n  }\n\n  .table-head-heading{\n    font-size: 14px;\n    font-weight: 600;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
