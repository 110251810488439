// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.display-lead {
  padding: 20px 0px;
  display: flex;
  width: 100%;
  overflow: auto;
}
.display-lead .lead-main-box .lead-demo {
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  width: 350px;
  padding: 0px 20px;
}
.display-lead .lead-main-box .lead-demo .demo-box {
  background: #ffbd2e;
  color: #000;
  width: fit-content;
  padding: 5px;
  border-radius: 10px;
}
.display-lead .lead-main-box .analyze-value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  background: #eef7fb;
  padding: 20px 30px;
  gap: 10px;
}
.display-lead .lead-main-box .analyze-value .value {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  margin-bottom: 0px;
}
.display-lead .lead-main-box .analyze-value .price {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 0px;
}
.display-lead .lead-main-box .all-leads {
  padding: 20px;
  background: #eef7fb;
  height: 520px;
  overflow: auto;
}
.display-lead .lead-main-box .all-leads::-webkit-scrollbar {
  width: 2px;
  background: lightgray;
}

.display-lead::-webkit-scrollbar {
  height: 5px;
  background: lightgray;
}`, "",{"version":3,"sources":["webpack://./src/Main/Sales/Leads/AllLeadsDisplay.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,aAAA;EACA,WAAA;EACA,cAAA;AACF;AACI;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,qBAAA;EACA,YAAA;EACA,iBAAA;AACN;AACM;EACE,mBAAA;EACA,WAAA;EACA,kBAAA;EACA,YAAA;EACA,mBAAA;AACR;AAEI;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;EACA,SAAA;AAAN;AACM;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,qBAAA;EACA,kBAAA;AACR;AACM;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AACR;AAEI;EACE,aAAA;EACA,mBAAA;EACA,aAAA;EACA,cAAA;AAAN;AAEI;EAEE,UAAA;EACA,qBAAA;AADN;;AAMA;EACE,WAAA;EACA,qBAAA;AAHF","sourcesContent":[".display-lead {\n  padding: 20px 0px;\n  display: flex;\n  width: 100%;\n  overflow: auto;\n  .lead-main-box {\n    .lead-demo {\n      font-size: 11px;\n      font-weight: 400;\n      line-height: 16px;\n      letter-spacing: 0.5px;\n      width: 350px;\n      padding: 0px 20px;\n\n      .demo-box {\n        background: #ffbd2e;\n        color: #000;\n        width: fit-content;\n        padding: 5px;\n        border-radius: 10px;\n      }\n    }\n    .analyze-value {\n      display: flex;\n      justify-content: space-between;\n      align-items: center;\n      padding: 0px 20px;\n      background: #eef7fb;\n      padding: 20px 30px;\n      gap: 10px;\n      .value {\n        font-size: 12px;\n        font-weight: 400;\n        line-height: 16px;\n        letter-spacing: 0.5px;\n        margin-bottom: 0px;\n      }\n      .price {\n        font-size: 12px;\n        font-weight: 400;\n        line-height: 16px;\n        margin-bottom: 0px;\n      }\n    }\n    .all-leads {\n      padding: 20px;\n      background: #eef7fb;\n      height: 520px;\n      overflow: auto;\n    }\n    .all-leads::-webkit-scrollbar {\n      // display: none;\n      width: 2px;\n      background: lightgray;\n    }\n  }\n}\n\n.display-lead::-webkit-scrollbar {\n  height: 5px;\n  background: lightgray;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
